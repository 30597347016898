<template>
    <div class="table_sty">
      <div class="froms" style="margin: 5px">
        <div>基本信息</div>
        <div class="wire"></div>
  
        <el-row :gutter="20">
          <el-col :span="8">
              <div class="message">
                <p>排班日期：</p>
                <p class="p1">{{ data.duty_date }}</p>
              </div>
          </el-col>
          <el-col :span="8">
              <div class="message" style="padding-left: 30px">
                <p>值班校领导：</p>
                <p class="p1">{{ data.leaders }}</p>
              </div>
          </el-col>
          <el-col :span="8">
            <div class="message">
              <p>值班中层领导：</p>
              <p class="p1">{{ data.middile_leaders }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
        <div class="froms particulars-pop">
            <el-table
                highlight-current-row
                :data="dataList"
                :header-cell-style="{ background: '#f8f8f9' }"
                style="flex: 1"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  :index="indexMethod"
                ></el-table-column>
                <el-table-column
                  prop="nickname"
                  label="值班人"
                ></el-table-column>
                <el-table-column
                  prop="department_name"
                  label="部门"
                ></el-table-column>
                  <el-table-column
                  prop=""
                  label="值班时间"
                >
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="scope.row.duty_time" placement="top-start">
                        <el-tag>{{scope.row.time_name}}</el-tag>
                    </el-tooltip>
                </template>
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="是否到岗"
                >
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.status">{{ scope.row.status_name }}</el-tag>
                    <el-tag type="danger" v-else="scope.row.status">{{ scope.row.status_name }}</el-tag>
                </template>
                </el-table-column>
  
                <el-table-column prop="name" label="值班日志">
                  <template slot-scope="scope">
                    <el-link
                    v-if="scope.row.status==2"
                      type="primary"
                      class="list_sty"
                      @click="on_particulars(scope.row)"
                      >查看</el-link
                    >
                    <el-link
                    v-else
                      type="default"
                      class="list_sty"
                      >上传</el-link
                    >
                  </template>
                </el-table-column>
              </el-table>
      </div>
    </div>
  </template>
  
  <script>
  import { dutyplanDetail } from "../../assets/request/api";
  import tablepop from "../../components/controlog/dangerous_logs";
  export default {
    name: "duty_particulars",
    components: { tablepop },
    data() {
      return {
        id: "",
        data: {},
        dataList: [],
      };
    },
    created() {
      let { id } = this.$route.query;
      this.id = id;
      dutyplanDetail({ id }).then((res) => {
        let {info,list} =res.data;
        this.data = info;
        this.dataList = list;
       
      });
    },
    methods: {
          //表格下标累加
    indexMethod(index) {
      index = index + 1;
      return index;
    },
    },
  };
  </script>
  <style lang="less" scoped>
  .message {
    display: flex;
    font-size: 15px;
    color: #28307c;
    margin-top: 10px;
    .p1 {
      color: #465269;
    }
  }
  .describe {
    display: inline-block;
    position: relative;
    padding: 4px;
    font-size: 16px;
    color: #0788ff;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .describe:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0788ff;
  }
  .segmentation {
    height: 1px;
    background-color: #f4f4f4;
    margin-bottom: 20px;
  }
  .result {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #19be6b;
    }
  }
  .wire {
    height: 1px;
    background: #e8eaec;
    margin: 10px -15px 0 -15px;
  }
  .imgs {
    display: flex;
  
    .el-image {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .sign {
    margin-left: 30px;
    display: flex;
    div {
      width: 30px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 9px;
      color: #ffffff;
      box-sizing: border-box;
    }
  }
  
  .sign-1 {
    border-radius: 5px 0 0 5px;
  }
  .sign-2 {
    border-radius: 0 5px 5px 0;
  }
  </style>
  