<template>
    <div class="block">
      <div class="title">
        <p>标题：{{ info.name }}</p>
        <p>发起时间：{{ info.add_time }}</p>
        <p>状态：<el-tag v-if="info.status==1">完成</el-tag><el-tag v-else>未完成</el-tag></p>
        <p v-if="info.status==1">完成时间：{{ info.completed_time }}</p>
      </div>
      <el-timeline> 
        <el-timeline-item
          v-for="(item, index) in operation_records"
          :key="index"
          :timestamp="item.date"
          placement="top"
        >
          <el-card>

            <h4>{{item.type_name}} : {{ item.remark }}</h4>
            <p>{{ item.nickname }} <el-tag v-if="item.result">({{item.result}})</el-tag></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </template>
  
  <script>
  import { dangerControlDetail } from "../../assets/request/api";
  export default {
    data() {
      return {
        info: {},
        operation_records: "",
      };
    },
    methods: {
      way_show(id) {
        dangerControlDetail({ id: id }).then((res) => {
          let info = res.data;
          this.info=info;
          this.operation_records = info.operation_records;
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .block {
    height: 50vh;
    overflow-y: scroll;
  }
  .title {
    margin-bottom: 10px;
    font-size: 14px;
    p {
      margin-bottom: 10px;
    }
  }
  </style>
  